import React from 'react';
import { Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStore } from '../../store';
import NavItem from '../../atoms/NavItem/NavItem';
import Header from '../Header/Header';
import {withUser} from "../../shared/LoginManager";
import './NavShell.css';

class NavShell extends React.Component {
    state = {
        menuIsActive: false,
        hidden: false,
    }

    static propTypes = {
        /** The user context */
        user: PropTypes.object
    };

    updateShellStyle = () => {
        const layouts = [
            { path: '/machine-learning/mapping', backgroundColor: 'lightgray' },
            { path: '/model-editor/report-template-viewer', backgroundColor: 'white' },
        ];

        const page = layouts.find(page => this.props.location.pathname.startsWith(page.path));

        document.body.style.backgroundColor = page?.backgroundColor || '#ededed';

        this.setState({ hidden: !!page });
    };

    componentDidMount = () => {
        this.updateShellStyle();
    };

    componentDidUpdate = (prevProps) => {
        const { pathname } = this.props.location;

        if (prevProps.location.pathname === pathname) {
            return;
        }

        this.updateShellStyle();
    };

    closeMenu = () => {
        this.setState({
            menuIsActive: false
        });
    };

    openMenu = () => {
        this.setState({
            menuIsActive: true
        });
    };

    render() {
        const { user, store } = this.props;
        const renderModelEditorNav = () => {
            if (store.menu.length < 1) return false;
            return (
                <ul>
                    { store.menu.map((item) => item) }
                </ul>
            )
        };

        if (this.state.hidden) {
            return this.props.children;
        }

        if (user.loggedIn) {
            const { pathname } = this.props.location;

            return (
                <main>
                    <nav className={(this.state.menuIsActive ? 'activeMenu' : '')}>
                        <div>
                            <h3 className={'title'}>Menu</h3>
                            <span className={'menuToggle'} onClick={this.closeMenu.bind(this)}>
                                <Icon name='x' />
                            </span>
                        </div>
                        <NavItem onClick={this.closeMenu} to='/' name='home' label='Forside'/>
                        <NavItem onClick={this.closeMenu} to='/accounts' name='users' label='Konti'/>
                        <NavItem onClick={this.closeMenu} to='/model-editor' name='share alternate' label='Modeller'/>
                        {renderModelEditorNav()}
                        <NavItem onClick={this.closeMenu} to='/tasks' name='tasks' label='Opgaver'/>
                        <NavItem onClick={this.closeMenu} to='/overview' name='tv' label='Dashboard'/>
                        <NavItem onClick={this.closeMenu} to='/payment' name='dollar' label='Betalingsadministration'/>
                        <NavItem onClick={this.closeMenu} to='/emails' name='mail' label='Nyhedsbrev'/>
                        <NavItem onClick={this.closeMenu} to='/notifications' name='bullhorn' label='Notifikations Center'/>
                        <NavItem onClick={this.closeMenu} to='/development' name='code' label='Udvikling'/>
                        <NavItem onClick={this.closeMenu} to='/machine-learning' name='chart bar' label='Machine learning'/>
                        {pathname.startsWith('/machine-learning') && (
                            <ul>
                                <NavItem onClick={this.closeMenu} to='/machine-learning/test' name='check' label='Test' />
                                <NavItem onClick={this.closeMenu} to='/machine-learning/mapping' name='tasks' label='Mapning' />
                            </ul>
                        )}
                        <NavItem onClick={this.closeMenu} to='/taxonomy' name='sitemap' label='Taksonomi'/>
                        <NavItem onClick={this.closeMenu} to='/saas-metrics' name='line graph' label='SaaS metrics'/>
                        <NavItem onClick={this.closeMenu} to='/hubspot' name='table' label='Hubspot'/>
                    </nav>
                    <div className={'contentShellWrapper'}>
                        <Header
                            openMenu={this.openMenu.bind(this)}/>
                        <section className={'contentShell'}>
                            {this.props.children}
                        </section>
                    </div>
                    <div className={this.state.menuIsActive ? 'overlay' : ''} onClick={this.closeMenu}/>
                </main>

            );
        } else {
            return (
                <main>
                    <div>
                        <section className={'contentShell'}>
                            <Header openMenu={this.openMenu}/>
                            {this.props.children}
                        </section>
                    </div>
                </main>
            );
        }
    }
}

export default withRouter(withUser(withStore(NavShell)));
