import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Icon, Loader, Message, Segment, Header, Button, Table } from 'semantic-ui-react';
import productEngine from 'http/productEngine';
import useRestResource from 'shared/hooks/useRestResource';
import FileuploadButton from 'atoms/FileuploadButton';
import { readAsText } from 'shared/files';
import { parseCSV } from 'shared/CSV';
import { toast } from 'react-toastify';

const DataSourceTables = () => {
    const { slug } = useParams();
    const history = useHistory();

    const fetchDataSourceTable = useCallback(() => {
        return productEngine.getDataSourceTableBySlug(slug);
    }, [slug]);

    const { loading, error, data: dataSourceTable, setLocalData } = useRestResource({ fetcher: fetchDataSourceTable });

    const handleSave = () => {
        productEngine.upsertDataSourceTable(dataSourceTable)
            .then(() => history.push('/model-editor'))
            .catch(err => toast.error(err.message));
    };

    const onCSVChosen = async file => {
        const rawCSV = await readAsText(file);
        const csv = parseCSV(rawCSV);

        const columnDataTypes = {};
        const rowsOfData = [];

        for (let rowIdx in csv) {
            rowIdx = Number(rowIdx);
            const row = csv[rowIdx];
            const parsedRowValues = {};
            for (const [columnKey, value] of Object.entries(row)) {
                const valueAsNumber = Number(value);

                let dataType;
                if (isNaN(valueAsNumber)) {
                    dataType = 'string';
                    parsedRowValues[columnKey] = value;
                } else {
                    dataType = 'number';
                    parsedRowValues[columnKey] = valueAsNumber;
                }

                // validate data type consistency across all rows
                if (!columnDataTypes[columnKey]) {
                    columnDataTypes[columnKey] = dataType;
                } else if (dataType !== columnDataTypes[columnKey]) {
                    const expectedType = columnDataTypes[columnKey];
                    toast.error(`Fejl på linje ${rowIdx + 1}: Forventede "${expectedType}" som data type, men fik "${dataType}"`);
                    return;
                }
            }
            rowsOfData.push({ values: parsedRowValues });
        }

        setLocalData({
            slug,
            rowsOfData: rowsOfData,
            columnsMetadata: Object.entries(columnDataTypes).map(([columnID, dataType]) => {
                return {
                    id: columnID,
                    dataType,
                };
            }),
        });
    };

    const renderContent = () => {
        if (loading) {
            return <Loader inline='centered' active />;
        }

        if (error) {
            return <Message><Icon name='warning sign' /> Der opstod en fejl</Message>
        }

        return (
            <div>
                <Header>Redigér data source tabel "{dataSourceTable.slug}"</Header>
                <div style={{ textAlign: 'right' }}>
                    <Button icon='save' content='Gem' primary onClick={handleSave} />
                </div>

                <Table>
                    <Table.Header>
                        {dataSourceTable.columnsMetadata.map(column => {
                            return <Table.HeaderCell>{column.id} ({column.dataType})</Table.HeaderCell>;
                        })}
                    </Table.Header>
                    <Table.Body>
                        {dataSourceTable.rowsOfData.map(({ values }) => {
                            return (
                                <Table.Row>
                                    {dataSourceTable.columnsMetadata.map(column => {
                                        return <Table.Cell>{values[column.id]}</Table.Cell>
                                    })}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={Object.keys(dataSourceTable.columnsMetadata).length} textAlign='right'>
                                <FileuploadButton
                                    content='Indlæs fra CSV'
                                    accept={['csv']}
                                    onChange={onCSVChosen}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        );
    };

    return (
        <Container>
            <br />
            <Segment>
                {renderContent()}
            </Segment>
        </Container>
    );
};

export default DataSourceTables;