import React, { useEffect, useState } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { getAppContentContainer } from 'shared/appContentContainer';
import { truncString } from 'shared/truncString';
import ColoredText from 'atoms/ColoredText';

const Block = ({
    id,
    label,
    icon,
    identation,
    onSelect,
    onExpand,
    onDelete,
    onCopy,
    onExpandAll,
    onCollapseAll,
    onLog,
    isHighlighted,
    isComposite,
    isCloser,
    isBeingDragged,
    isLabelOverwritten,
    expanded,
    levelOfHighlight,
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (!menuOpen) {
            return;
        }

        const onScroll = () => setMenuOpen(false);
        const appContainer = getAppContentContainer();

        appContainer.addEventListener('scroll', onScroll);
        return () => appContainer.removeEventListener('scoll', onScroll);
    }, [menuOpen, setMenuOpen]);

    let groupIcon;
    if (isComposite) {
        groupIcon = expanded ? 'caret down' : 'caret right';
    }

    if (isCloser) {
        groupIcon = expanded ? 'caret up' : 'caret right';
    }

    const blockLabel = !isCloser && (
        <span
            style={{ fontStyle: isLabelOverwritten ? 'italic' : 'normal' }}
            children={truncString(label)}
        />
    );

    return (
        <div
            id={id}
            style={{ display: 'flex' }}
            className='noselect'
        >
            {
                [...new Array(identation).keys()]
                .map((level) => {
                    const levelIsHighlighted = level === levelOfHighlight;
                    return (
                        <div style={{ width: '27px', textAlign: 'center' }}>
                            <div style={{
                                display: 'inline-block',
                                height: '100%',
                                width: '2px',
                                borderRight: `1px solid ${isBeingDragged ? 'transparent' : '#46c476'}`,
                                opacity: levelIsHighlighted ? 1 : 0,
                            }} />
                        </div>
                    );
                })
            }
            <div
                style={{
                    flexGrow: 1,
                    background: isHighlighted ? 'white' : 'rgb(248, 248, 248)',
                    border: `1px solid ${isBeingDragged ? 'green' : 'lightgray'}`,
                    position: 'relative',
                    marginTop: '-1px',
                    marginLeft: '-1px',
                    marginRight: '-1px',

                    padding: '5px',
                }}
                onClick={onSelect}
                onDoubleClick={isComposite ? onExpand : undefined}
            >
                <Icon name={icon} color={isHighlighted ? 'green' : 'black'} />
                {groupIcon && <Icon name={groupIcon} link onClick={onExpand} />}
                {blockLabel}
                {
                    isHighlighted &&
                    <Popup
                        on='click'
                        position='top center'
                        open={menuOpen}
                        onOpen={() => setMenuOpen(true)}
                        onClose={() => setMenuOpen(false)}
                        trigger={
                            !isCloser &&
                            <Icon
                                style={{ float: 'right' }}
                                name='ellipsis horizontal'
                                color='grey'
                                link
                            />
                        }
                        content={
                            <List divided>
                                {
                                    [
                                        ['Delete', 'x', onDelete, true],
                                        ['Clone', 'copy', onCopy, true],
                                        ['Log to console', 'code', onLog, true],
                                        ['Expand all', 'expand', onExpandAll, isComposite],
                                        ['Collapse all', 'compress', onCollapseAll, isComposite],
                                    ]
                                    .map(([label, icon, onClick, enabled]) => {
                                        return <List.Item key={label}>
                                            <ColoredText
                                                onClick={() => {
                                                    onClick();
                                                    setMenuOpen(false);
                                                }}
                                                iconPosition='left'
                                                underlined={false}
                                                content={label}
                                                disabled={!enabled}
                                                icon={icon}
                                                link
                                            />
                                        </List.Item>
                                    })
                                }
                            </List>
                        }
                    />
                }
            </div>
        </div>
        
    );
};

export default Block;