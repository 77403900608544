import uuid from 'uuid/v4';
import React, { useState } from 'react';
import { Button, Icon, Input, Modal, Table } from 'semantic-ui-react';
import { dataTypeDescriptions, dataTypes } from 'model-editor/nodeMetadata';

const SubResourceForm = ({ predefinedResources, resourceTypes, chosenResource, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Button
                fluid
                icon='pencil'
                content={`${predefinedResources.length} oprettet`}
                onClick={() => setModalOpen(true)}
            />
            {modalOpen && (
                <Modal open onClose={() => setModalOpen(false)} size='large'>
                    <Modal.Content>
                        <PredefinedResourcesEditor
                            chosenResource={chosenResource}
                            predefinedResources={predefinedResources}
                            resourceTypes={resourceTypes}
                            onChange={onChange}
                        />
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

const PredefinedResourcesEditor = ({ chosenResource, predefinedResources, onChange, resourceTypes }) => {
    const createNewResource = () => {
        const newPredefinedResources = [...predefinedResources, {
            id: uuid(),
            data: {},
        }];

        onChange(newPredefinedResources);
    };

    const updateResourceByID = (id, updator = {}) => {
        const newPredefinedResources = predefinedResources.map(resource => {
            if (resource.id !== id) {
                return resource;
            }

            return {
                ...resource,
                data: {
                    ...resource.data,
                    ...updator,
                },
            };
        });

        onChange(newPredefinedResources);
    };

    const deleteResourceByID = id => {
        onChange(predefinedResources.filter(resource => id !== resource.id));
    };

    const predefinedResourceRows = predefinedResources.map(predefinedResource => {
        return (
            <Table.Row key={predefinedResource.id}>
                {chosenResource.properties.map(property => {
                    let formComponent;

                    switch (property.dataType) {
                        case dataTypes.STRING: {
                            formComponent = (
                                <Input
                                    fluid
                                    defaultValue={predefinedResource.data[property.tag]}
                                    onChange={(_, { value }) => {
                                        updateResourceByID(predefinedResource.id, { [property.tag]: value });
                                    }}
                                />
                            );
                            break;
                        }

                        case dataTypes.RESOURCE: {
                            const resourceTemplate = resourceTypes.find(x => x.slug === property.subResourceTemplateSlug);

                            formComponent = (
                                <SubResourceForm
                                    predefinedResources={predefinedResource.data[property.tag] || []}
                                    resourceTypes={resourceTypes}
                                    chosenResource={resourceTemplate}
                                    onChange={newResources => {
                                        console.log(newResources, predefinedResource.id, property.tag);
                                        updateResourceByID(predefinedResource.id, { [property.tag]: newResources });
                                    }}
                                />
                            );
                            break;
                        }

                        default: {
                            formComponent = (
                                <div>
                                    Datatype "{dataTypeDescriptions[property.dataType]}"{' '}
                                    ikke understøttet!
                                </div>
                            );
                        }
                    }

                    return (
                        <Table.Cell>
                            {formComponent}
                        </Table.Cell>
                    );
                })}
                <Table.Cell width={1} textAlign='right'>
                    <Icon
                        link
                        color='red'
                        name='trash'
                        onClick={() => deleteResourceByID(predefinedResource.id)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    {chosenResource.properties.map(property => {
                        return (
                            <Table.HeaderCell>
                                {property.name}
                            </Table.HeaderCell>
                        );
                    })}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {predefinedResourceRows}
            </Table.Body>
            <Table.Footer>
                <Table.Row textAlign='right'>
                    <Table.HeaderCell colSpan={chosenResource.properties.length + 1}>
                        <Button
                            primary
                            icon='plus'
                            content='Opret ny ressource'
                            onClick={createNewResource}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

export default PredefinedResourcesEditor;