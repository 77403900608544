import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Dropdown, Form, Table } from 'semantic-ui-react';
import { dataTypes, nodeTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';

const AnnualRolloverEditor = ({
    resource,
    questions,
    calculations,
    functionCalls,
    annualRolloverMapping,
    setAnnualRolloverMapping,
    lastYearResourceNodeID,
    setLastYearResourceNodeID,
    lastYearResourceNode,
    modelMetadata,
}) => {
    const [lastYearModelResourceNodes, setLastYearModelResourceNodes] = useState(null);

    const lastYearModelID = modelMetadata?.previousModel;

    useEffect(() => {
        if (!lastYearModelID) return;

        modelEditor.getModelNodes(lastYearModelID)
            .then(nodes => {
                const resourceNodes = nodes.filter(node => {
                    return node.type === nodeTypes.MultipleResources;
                });
                setLastYearModelResourceNodes(resourceNodes);
            })
            .catch(e => toast.error(`Kunne ikke hente sidste års ressourceknuder: ${e.message}`));
    }, [lastYearModelID]);

    const numberTagOptions = useMemo(() => {
        const options = [
            // questions
            ...questions.filter(question => {
                return question.type === dataTypes.NUMBER;
            }).map(({ tag }) => ({
                tag,
                icon: 'question circle',
            })),

            // calculations
            ...calculations.map(({ tag }) => ({
                tag,
                icon: 'calculator',
            })),

            // function calls
            ...functionCalls.flatMap(functionCall => {
                return Object.values(functionCall.outputMappings).map(tag => ({
                    tag,
                    icon: 'cog',
                }));
            }),
        ].map(({ tag, icon }) => ({
            key: tag,
            text: tag,
            value: tag,
            icon,
        }));

        options.sort((a, b) => {
            if (a.tag > b.tag) return 1;
            if (a.tag < b.tag) return -1;
            return 0;
        });

        return options;
    }, [questions, calculations, functionCalls]);

    if (!resource) {
        return null;
    }

    const annualRolloverValues = resource?.annualRolloverValues || [];

    return (
        <>
            {modelMetadata.previousModel && (
                <Form>
                    <Form.Dropdown
                        key={lastYearResourceNodeID}
                        selection
                        search
                        label='Vælg ressourceknude fra sidste års model'
                        defaultValue={lastYearResourceNodeID}
                        loading={lastYearModelResourceNodes === null}
                        onChange={(_, { value }) => setLastYearResourceNodeID(value)}
                        options={lastYearModelResourceNodes?.map(node => {
                            return {
                                text: node.name,
                                value: node.id,
                            };
                        })}
                    />
                </Form>
            )}

            {annualRolloverValues.length > 0 && (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Rulningsværdi</Table.HeaderCell>
                            <Table.HeaderCell>Tag fra ressourceknuden</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {annualRolloverValues.map(({ id, tag }) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>
                                        {tag}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Dropdown
                                            clearable
                                            selection
                                            search
                                            fluid
                                            defaultValue={annualRolloverMapping[tag]}
                                            options={numberTagOptions}
                                            onChange={(_, { value }) => {
                                                setAnnualRolloverMapping({
                                                    ...annualRolloverMapping,
                                                    [tag]: value,
                                                });
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
        </>
    );
};

export default AnnualRolloverEditor;