import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import TaxonomyParser from 'http/taxonomyParser';

import { countryOptions } from './model';
import useRestResource from 'shared/hooks/useRestResource';
import reportEditor from 'http/reportEditor';
import Tooltip from 'atoms/Tooltip';
import FileuploadButton from 'atoms/FileuploadButton';
import { readAsBase64 } from 'shared/files';

const fetchFonts = () => reportEditor.getAssets('font');

const FontFormField = ({ defaultValue, onChange }) => {
    const fontsResource = useRestResource({
        fetcher: fetchFonts,
    });

    const fileUploadIcon = (
        <FileuploadButton
            accept={['ttf']}
            onChange={async file => {
                await reportEditor.uploadAsset({
                    category: 'font',
                    dataBase64: await readAsBase64(file),
                    contentType: 'font/ttf',
                    fileName: file.name,
                });
                fontsResource.refetch();
            }}
            triggerOverride={openFileDialog => {
                return (
                    <Tooltip
                        trigger={<Icon name='upload' link onCLick={openFileDialog} />}
                        content='Upload new font'
                        position='right center'
                        basic
                    />
                );
            }}
        />
    );

    return (
        <Form.Field>
            <label>
                Font {fileUploadIcon}
            </label>
            <Dropdown
                selection
                search
                clearable
                placeholder='Leave empty to use default font'
                onChange={(_, { value }) => onChange(value)}
                defaultValue={defaultValue}
                loading={fontsResource.loading}
                error={!!fontsResource.error}
                options={(fontsResource.data || []).map(fontAsset => {
                    return {
                        text: fontAsset.fileName,
                        value: fontAsset._id,
                    };
                })}
            />
        </Form.Field>
    );
};

const MetadataEditor = ({ metadata, setMetadata, isParsingTaxonomy }) => {
    const [taxonomyVersions, setTaxonomyVersions] = useState([]);
    
    useEffect(() => {
        (async () => {
            const taxonomyVersions = await TaxonomyParser.getTaxonomyVersions();
            setTaxonomyVersions(taxonomyVersions);
        })();
    }, []);

    const awaitingTaxonomy = taxonomyVersions.length === 0 || isParsingTaxonomy;

    return (
        <Form style={{ width: '100%', borderSpacing: '0px' }}>
            <Form.Field>
                <label>Internal name</label>
                <Input
                    defaultValue={metadata.name}
                    onChange={(_, { value }) => setMetadata({ ...metadata, name: value })}
                />
            </Form.Field>
            <Form.Field>
                <label>Taxonomy</label>
                <Dropdown
                    selection
                    defaultValue={metadata.taxonomy}
                    placeholder='Select a taxonomy'
                    onChange={async (_, { value }) => {
                        setMetadata({
                            ...metadata,
                            taxonomy: value,
                        });
                    }}
                    options={taxonomyVersions.map(t => ({ key: t.id, value: t.id, text: t.displayName }))}
                    loading={awaitingTaxonomy}
                    disabled={awaitingTaxonomy}
                />
            </Form.Field>
            <Form.Field>
                <label>Supported languages</label>
                <Dropdown
                    options={countryOptions}
                    defaultValue={metadata.supportedLanguages}
                    placeholder='Select language(s)'
                    onChange={(_, { value }) => setMetadata({ ...metadata, supportedLanguages: value })}
                    multiple
                    fluid
                    search
                    selection
                />
            </Form.Field>
            <Form.Field>
                <label>Document output format</label>
                <Dropdown
                    selection
                    fluid
                    defaultValue={metadata.outputAsIXBRL || false}
                    onChange={(_, { value }) => setMetadata({ ...metadata, outputAsIXBRL: value })}
                    options={[
                        {
                            text: 'PDF',
                            value: false,
                        },
                        {
                            text: 'iXBRL',
                            value: true,
                        },
                    ]}
                />
            </Form.Field>
            <FontFormField
                defaultValue={metadata.fontAssetID}
                onChange={fontAssetID => setMetadata({ ...metadata, fontAssetID })}
            />
        </Form>
    );
};

export default MetadataEditor;