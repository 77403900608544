import lodash from 'lodash';
import React, { Component } from 'react';
import { Loader, Menu, Segment } from 'semantic-ui-react';
import { dataTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';
import productEngine from 'http/productEngine';
import GeneralSettingsEditor from './GeneralSettingsEditor';
import InputsEditor from './InputsEditor';
import QuestionEditor from './QuestionEditor';
import CalculationEditor from './CalculationEditor';
import FunctionCallsEditor from './FunctionCallsEditor';
import FieldTemplateEditor from './FieldTemplateEditor';
import DeactivationRuleEdtior from './DeactivationRulesEditor';
import AnnualRolloverEditor from './AnnualRolloverEditor';
import PredefinedResourcesEditor from './PredefinedResourcesEditor';
import TablesEditor from './TablesEditor';

import collectAllTagsAssociatedWithResource from './collectAllTagsAssociatedWithResource';
import prepareResourceForSave from './prepareResourceForSave';

import styles from './NodeDataMultipleResources.module.css';

class NodeDataMultipleResources extends Component {
    static lastSelectedTabStorageKey = 'lastSelectedResourceTab';

    constructor(props) {
        super(props);

        this.tabs = [
            {
                id: 'resource',
                icon: 'cog',
                name: 'Ressource',
                render: this.renderResourceTab,
            },
            {
                id: 'predefined_resources',
                icon: 'boxes',
                name: 'Predefinerede ressourcer',
                render: this.renderPredefinedResourcesTab,
                isHidden: () => {
                    const chosenResource = this.getChosenResource();
                    return !chosenResource?.properties?.length;
                },
            },
            {
                id: 'inputs',
                icon: 'sign in',
                name: 'Inputs',
                render: this.renderInputsTab,
                isHidden: () => this.getDependencyNodes().length === 0,
            },
            {
                id: 'questions',
                icon: 'question circle',
                name: 'Spørgsmål',
                render: this.renderQuestionsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                icon: 'calculator',
                id: 'calculations',
                name: 'Udregninger',
                render: this.renderCalculationsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                icon: 'cogs',
                id: 'functions',
                name: 'Funktioner',
                render: this.renderFunctionsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                id: 'field_templates',
                icon: 'file alternate',
                name: 'Feltskabeloner',
                render: this.renderFieldTemplatesTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                id: 'tables',
                icon: 'table',
                name: 'Tabeller',
                render: this.renderTablesTab,
                isHidden: () => {
                    const chosenResource = this.getChosenResource();
                    if (!chosenResource) return true;
                    if (chosenResource.properties.length > 0) return true;
                    return false;
                },
            },
            {
                id: 'annual_rollover',
                icon: 'chart bar',
                name: 'Årsrul',
                render: this.renderAnnualRolloverTab,
                isDisabled: () => !this.state.data.resource,
                isHidden: () => {
                    if (this.state.modelMetadata.previousModel) return false;
 
                    const chosenResource = this.getChosenResource();
                    const annualRolloverValues = chosenResource?.annualRolloverValues || [];
                    if (annualRolloverValues.length > 0) return false;

                    return true;
                },
            },
            {
                id: 'deactivation_rules',
                icon: 'power',
                name: 'Regler for deaktivering',
                render: this.renderDeactivationRulesTab,
                isDisabled: () => !this.state.data.resource,
            },
        ];

        // extract node specific data from props
        const data = lodash.get(props, 'node.data', {});

        // default find tab
        let defaultTabIdx = 0;

        const lastSelectedTab = localStorage[NodeDataMultipleResources.lastSelectedTabStorageKey];
        if (lastSelectedTab) {
            const idx = this.tabs.findIndex(t => t.id === lastSelectedTab);
            if (idx !== -1) defaultTabIdx = idx; 
        }

        this.state = {
            activeTabID: this.tabs[defaultTabIdx].id,
            resourceTypes: [],
            signatures: [],
            resourceFunctions: [],
            modelMetadata: {},
            loading: true,

            // saved server side
            data: {
                resource: data?.resource || '',
                tables: data?.tables || [],
                questions: data?.questions || [],
                calculations: data?.calculations || [],
                functionCalls: data?.functionCalls || [],
                fieldTemplates: data?.fieldTemplates || [],
                deactivationRules: data?.deactivationRules || [],
                predefinedResources: data?.predefinedResources || [],
                inputTagsSettings: data?.inputTagsSettings || {},
                annualRolloverMapping: data?.annualRolloverMapping || {},
                subResourcesConfig: data?.subResourcesConfig || {},
                lastYearResourceNodeID: data?.lastYearResourceNodeID || '',
            },
        };
    }

    componentDidMount = async () => {
        const [modelMetadata] = await Promise.all([
            this.loadModelMetadata(),
            this.loadResourceTypes(),
            this.loadFunctionSignatures(),
            this.loadResourceFunctions(),
        ]);

        await this.loadLastYearModelResourceNode(modelMetadata.previousModel, this.props.node?.data?.lastYearResourceNodeID);

        this.setState({
            loading: false,
        });
    };

    componentDidUpdate = async () => {
        const lastYearResourceNodeID = this.state.data?.lastYearResourceNodeID;

        if (lastYearResourceNodeID && !this.state.lastYearResourceNode) {
            await this.loadLastYearModelResourceNode(this.state.modelMetadata.previousModel, lastYearResourceNodeID);
        }
    };

    loadModelMetadata = async () => {
        const modelMetadata = await modelEditor.getModelMetadata(this.props.modelId);

        this.setState({ modelMetadata });

        return modelMetadata;
    };

    loadLastYearModelResourceNode = async (previousModelID, lastYearResourceNodeID) => {
        if (!lastYearResourceNodeID) return;
        if (!previousModelID) return;

        const lastYearResourceNode = await modelEditor.getNodeByID(previousModelID, lastYearResourceNodeID);
        this.setState({ lastYearResourceNode });
    };

    loadFunctionSignatures = async () => {
        const signatures = await modelEditor.getCalculationFunctions();

        this.setState({ signatures });
    };

    loadResourceTypes = async () => {
        const { data } = this.state;

        const resourceTypes = await productEngine.getResourceTemplates();

        this.setState({
            resourceTypes,
            data,
        });
    };

    loadResourceFunctions = async () => {
        const resourceFunctions = await productEngine.getResourceFunctions();

        this.setState({ resourceFunctions });
    };

    getData = () => {
        const chosenResource = this.getChosenResource();

        const mainResource = prepareResourceForSave({
            chosenResource,
            resourceData: this.state.data,
            resourceFunctions: this.state.resourceFunctions,
            dependencyNodes: this.getDependencyNodes(),
        });

        for (const [propertyTag, subResourceData] of Object.entries(mainResource.subResourcesConfig)) {
            const { subResourceTemplateSlug } = chosenResource.properties.find(property => {
                return property.tag === propertyTag;
            });

            const subResourceTemplate = this.state.resourceTypes.find(resourceType => {
                return resourceType.slug === subResourceTemplateSlug;
            });
            
            mainResource.subResourcesConfig[propertyTag] = prepareResourceForSave({
                chosenResource: subResourceTemplate,
                resourceData: subResourceData,
            });
        }

        return mainResource;
    };

    updateData = mutator => {
        const { data } = this.state;

        for (let [key, value] of Object.entries(mutator)) {
            data[key] = value;
        }

        this.setState({ data });
    };

    getActiveTab = () => {
        return this.tabs.find(tab => tab.id === this.state.activeTabID);
    };

    getChosenResource = () => {
        const { resourceTypes, data } = this.state;

        if (!data.resource) {
            return null;
        }

        return resourceTypes.find(type => {
            return type.slug === data.resource;
        });
    };

    getDependencyNodes = () => {
        const edges = this.props.node?.edges || [];
        const nodes = this.props.nodes;

        return edges.map(edge => {
            return nodes.find(node => node.id === edge);
        });
    };

    prepareFieldTag = ({ id, name, dataType, nodeType }) => {
        return {
            id,
            name,
            dataType,
            type: nodeType,
        };
    };

    prepareFieldTags = () => {
        const { data, lastYearResourceNode } = this.state;

        return collectAllTagsAssociatedWithResource({
            chosenResource: this.getChosenResource(),
            resourceData: data,
            lastYearResourceData: lastYearResourceNode?.data,
            resourceFunctions: this.state.resourceFunctions,
            dependencyNodes: this.getDependencyNodes(),
        });
    };

    prepareTagMap = () => {
        const fieldTags = this.prepareFieldTags();
        const tagMap = {};
        for (let fieldTag of fieldTags) {
            tagMap[fieldTag.id] = fieldTag;
        }
        return tagMap;
    };

    renderActiveTab = () => {
        const activeTab = this.getActiveTab();

        return activeTab.render();
    };

    setActiveTab = id => {
        localStorage[NodeDataMultipleResources.lastSelectedTabStorageKey] = id;
        this.setState({ activeTabID: id });
    };

    renderTab = ({ id, name, icon, isDisabled, isHidden }, activeTabID) => {
        let tabIsDisabled;

        if (isHidden instanceof Function) {
            const shouldHide = isHidden();
            if (shouldHide) {
                return null;
            }
        }

        // decide whether the tab is active
        if (isDisabled instanceof Function) {
            tabIsDisabled = isDisabled();
        } else {
            tabIsDisabled = false;
        }

        return (
            <Menu.Item        
                content={name}
                icon={icon}
                active={id === activeTabID}
                onClick={() => this.setActiveTab(id)}
                disabled={tabIsDisabled}
            />
        );
    };

    renderTabsMenu = () => {
        const activeTab = this.getActiveTab();
        const activeTabID = activeTab.id;

        return (
            <Menu secondary pointing>
                {this.tabs.map(tab => this.renderTab(tab, activeTabID))}
            </Menu>
        );
    };

    renderResourceTab = () => {
        const chosenResource = this.getChosenResource();

        return (
            <GeneralSettingsEditor
                signatures={this.state.signatures}
                resourceTypes={this.state.resourceTypes}
                subResourcesConfig={this.state.data.subResourcesConfig || {}}
                chosenResource={chosenResource}
                updateData={this.updateData}
            />
        );
    };

    renderPredefinedResourcesTab = () => {
        const chosenResource = this.getChosenResource();
        if (!chosenResource) {
            return null;
        }

        const predefinedResources = this.state.data.predefinedResources || [];

        return (
            <PredefinedResourcesEditor
                chosenResource={chosenResource}
                predefinedResources={predefinedResources}
                onChange={newPredefinedResources => this.updateData({ predefinedResources: newPredefinedResources })}
                resourceTypes={this.state.resourceTypes}
            />
        );
    };

    renderInputsTab = () => {
        return (
            <InputsEditor
                inputNodes={this.getDependencyNodes()}
                inputTagsSettings={this.state.data.inputTagsSettings}
                onChange={inputTagsSettings => this.updateData({ inputTagsSettings })}
            />
        );
    };

    renderQuestionsTab = () => {
        return (
            <QuestionEditor
                questions={this.state.data.questions}
                onChange={questions => this.updateData({ questions })}
            />
        );
    };

    renderCalculationsTab = () => {
        const { calculations } = this.state.data;
        const fieldIds = this.prepareFieldTags();

        return (
            <CalculationEditor
                calculations={calculations}
                fieldIds={fieldIds}
                signatures={this.state.signatures}
                onChange={calculations => this.updateData({ calculations })}
            />
        );
    };

    renderFunctionsTab = () => {
        return (
            <FunctionCallsEditor
                fieldIds={this.prepareFieldTags()}
                functionCalls={this.state.data.functionCalls}
                onChange={functionCalls => this.updateData({ functionCalls })}
                resourceFunctions={this.state.resourceFunctions}
            />
        );
    };

    renderFieldTemplatesTab = () => {
        // expose resource properties, questions and calculations to the field manager
        const fieldIds = this.prepareFieldTags();

        const radioOptionsGetter = tag => {
            const question = this.state.data.questions.find(question => question.tag === tag);
            if (question) {
                return (question.options || []).map(({ optionText, optionValue }) => {
                    return {
                        text: optionText,
                        value: optionValue,
                        key: optionValue,
                    };
                });
            }
            
            const resourceProp = this.getChosenResource()?.properties?.find(property => property.tag === tag);
            if (resourceProp) {
                return (resourceProp.options || []).map(({ label, value }) => {
                    return {
                        text: label,
                        value: value,
                        key: value,
                    };
                })
            }

            return [];
        };

        const chosenResource = this.getChosenResource();
        const resourceProps = (chosenResource?.properties || []).filter(property => {
            return property.dataType === dataTypes.RESOURCE;
        });

        const subResourceOptions = resourceProps.map(prop => {
            const config = this.state.data.subResourcesConfig[prop.tag];
            return {
                id: prop.tag,
                name: prop.name,
                data: config,
            };
        });

        return (
            <FieldTemplateEditor
                fieldIds={fieldIds}
                fieldTemplates={this.state.data.fieldTemplates}
                onChange={fieldTemplates => this.updateData({ fieldTemplates })}
                radioOptionsGetter={radioOptionsGetter}
                subResourceOptions={subResourceOptions}
            />
        );
    };

    renderTablesTab = () => {
        return (
            <TablesEditor
                fieldIds={this.prepareFieldTags()}
                tables={this.state.data.tables || []}
                onChange={tables => this.updateData({ tables })}
            />
        );
    };

    renderDeactivationRulesTab = () => {
        const fieldIds = this.prepareFieldTags();

        return (
            <DeactivationRuleEdtior
                fieldIds={fieldIds}
                deactivationRules={this.state.data.deactivationRules}
                onChange={deactivationRules => this.updateData({ deactivationRules })}
            />
        );
    };

    renderAnnualRolloverTab = () => {
        const resource = this.getChosenResource();
        const { questions, calculations, annualRolloverMapping, functionCalls, lastYearResourceNodeID } = this.state.data;

        return (
            <AnnualRolloverEditor
                resource={resource}
                questions={questions}
                calculations={calculations}
                functionCalls={functionCalls}
                annualRolloverMapping={annualRolloverMapping}
                setAnnualRolloverMapping={annualRolloverMapping => this.updateData({ annualRolloverMapping })}
                lastYearResourceNodeID={lastYearResourceNodeID}
                setLastYearResourceNodeID={lastYearResourceNodeID => this.updateData({ lastYearResourceNodeID })}
                lastYearResourceNode={this.state.lastYearResourceNode}
                modelMetadata={this.state.modelMetadata}
            />
        );
    };

    render = () => {
        const { loading } = this.state;

        if (loading) {
            return (
                <Loader inline='centered' active />
            );
        }

        return (
            <Segment className={styles.container}>
                {this.renderTabsMenu()}
                {this.renderActiveTab()}
            </Segment>
        );
    };
}

export default NodeDataMultipleResources;