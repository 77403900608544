import React, { Component, createRef, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Form,
  Input,
  Message,
  Icon,
  Checkbox,
  Transition,
  Header,
  Segment,
  Menu,
  Loader,
  Divider,
  TextArea,
  FeedLike,
  Image,
  ModalDescription,
} from "semantic-ui-react";
import accounts from "http/accounts";
import email from "http/email";
import ReportingEntitiesMaster from "pages/Accounts/ReportingEntities/master";
import AutoStamp from "atoms/AutoStamp";
import { withRESTResource } from "molecules/withRESTResource";
import { withUser } from "shared/LoginManager";
import { toast } from "react-toastify";
import { stampDescription } from "shared/dateTimeFormatting";
import LogoPicker from "model-editor/pages/ReportTemplates/Detail/InputEditor/LogoPicker";
import logo from "../../../images/digital-revisor/digital-revisor-logo-icon.svg";
import { prompt } from "shared/globalModal";

import {
  FeedSummary,
  FeedExtra,
  FeedEvent,
  FeedContent,
  Feed,
  Dropdown,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

const SendButton = ({ audienceQuery, onClick, disabled }) => {
  const [audienceCount, setAudienceCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const performFetch = async () => {
      const resp = await accounts.countAudienceTargets({
        audienceQuery,
      });
      setAudienceCount(resp);
      setLoading(false);
    };
    performFetch();
  }, [audienceQuery]);

  useEffect(() => {}, [audienceCount]);

  return (
    <Button
      primary
      onClick={() => onClick(audienceCount)}
      disabled={disabled || loading || audienceCount === 0}
      loading={loading}
      content={`Send til ${audienceCount} ${
        audienceCount === 1 ? "bruger" : "brugere"
      }!`}
      labelPosition="right"
      size="huge"
      icon="send"
    />
  );
};

class NotificationDetail extends Component {
  constructor() {
    super();
    this.tabs = [
      { name: "Indstillinger", render: this.renderSettings },
      { name: "Målgruppe", render: this.renderTargetGroup },
      { name: "Afsendelse", render: this.renderSending },
    ];
    this.state = {
      tab: this.tabs[0],
      notification: null,
      audience: [],
      sendError: null,
      isReady: false,
      remKey: 0,
      isNotificationSent: false,
      validateTitle: true,
      validateMSG: true,
      errorMSG: "",
      isNotificationValid: false,
      selectedOption: "",
    };
    this.audienceSelectorRef = createRef();
  }

  doWork = (todo) => {
    return async (...args) => {
      this.setState({ working: true });
      await todo(...args);
      this.setState({ working: false });
    };
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.rest.loading && !this.props.rest.loading) {
      if (this.props.rest.data) {
        this.setState({
          notification: this.props.rest.data,
          selectedOption: this.props.rest.data.type,
        });
      } else if (this.props.rest.error) {
        this.setState({ error: this.props.rest.error });
      } else {
        this.setState({ error: "No data found" });
      }
    }
  };

  updateNotification = (propsToUpdate = {}) => {
    const { notification } = this.state;
    for (let [key, value] of Object.entries(propsToUpdate)) {
      notification[key] = value;
    }
    this.setState({ notification });
  };

  saveNotification = async () => {
    const {
      _id,
      messageBody,
      audienceSelector,
      title,
      blogURL,
      type,
      link,
      image,
    } = this.state.notification;

    const toUpdate = {
      messageBody,
      audienceSelector,
      title,
      blogURL,
      type,
      link,
      image,
    };

    await email.patchNotification(_id, toUpdate);

    return true;
  };


  promptUserSpecificTestNotification = async () => {
    const { _id } = this.state.notification;
    const { user } = this.props.user;

    const inputEmail = await prompt({
      header: "Indtast e-mail",
      value: user.email,
    });

    const selectedUser = await accounts.getAccount(inputEmail);
    const testUser = await accounts.getAccountLogins(selectedUser.id);

    this.setState({ working: true });
    try {
      for(let i = 0; i < testUser.length; i++){
      
      await email.sendTestNotification(_id, testUser[i].id);
    }
      toast.success(
        "Test Notifikation sendt til " + selectedUser.getDisplayName()
      );
    } catch ({ message }) {
      toast.error(message);
    } finally {
      this.setState({ working: false });
    }
  };

  sendNotification = async (expectedReceiverCount) => {
    const { _id } = this.state.notification;
    const { user } = this.props.user;
    try {
      await this.saveNotification();
      await email.sendNotification(_id, expectedReceiverCount, user.login.id);
      this.setState({ isNotificationSent: true });
      toast.success(`"${this.state.notification.title}" blev sendt`);
      this.props.history.push("/notifications/notifications");
    } catch (error) {
      this.setState({ sendError: error });
    }
  };
  saveAndExit = async () => {
    const success = await this.saveNotification();

    if (success) {
      toast.success(`"${this.state.notification.title}" blev gemt`);
    }
  };

  /* Handles actions when validate is clicked,  */
  handleClick = async () => {
    const { notification } = this.state;
    let validateTitle = true;
    let isNotificationValid = false;
    let validateMSG = true;
    let validateIMG = true;
    let errorMSG = "";

    if (!notification.title) {
      validateTitle = false;
      errorMSG = "Titel er påkrævet.";
    }

    if (notification.type === "domain") {
      if (!notification.image) {
        validateIMG = false;
        errorMSG = "Et billede er påkrævet.";
      }

      if (notification.image && notification.image.length > 100000) {
        validateIMG = false;
        errorMSG = "Billedet er for stort, vælg venligst et mindre billede.";
      }
    }

    if (!notification.messageBody) {
      validateMSG = false;
      errorMSG = "Besked er påkrævet.";
    }

    if (!validateTitle || !validateMSG || !validateIMG) {
      this.setState({ validateTitle, validateMSG, errorMSG });
      return;
    }

    this.setState({ validateTitle, validateMSG, errorMSG });

    if (validateTitle && validateMSG && validateIMG) {
      const success = await this.saveNotification();
      if (success) {
        isNotificationValid = true;
        toast.success("Notifikationen er valideret");
        this.setState({ isNotificationValid });
      }
    }
  };
  renderSettings = () => {
    const {
      notification,
      validateTitle,
      validateMSG,
      isNotificationValid,
      errorMSG,
      validateIMG,
    } = this.state;

    const notficationOptions = [
      { key: "default", text: "Default", value: "default" },
      { key: "domain", text: "Pop-Up", value: "domain" },
    ];

    const handleDropdownChange = (e, { value }) => {
      this.setState({ selectedOption: value });
      this.updateNotification({ type: value });
    };

    return (
      <Grid>
        {/* Render inputs based on notification type */}
        <Grid.Column width={8}>
          <Form>
            <Form.Field>
              <label>Notifikations Type</label>
              <Dropdown
                search
                selection
                closeOnChange
                options={notficationOptions}
                onChange={handleDropdownChange}
                onLabelClick={(_, { value }) =>
                  this.updateNotification({ type: value })
                }
                disabled={notification.isSent || isNotificationValid}
                placeholder="Vælg notifikations type"
                defaultValue={notification.type}
              />{" "}
            </Form.Field>
            {this.state.selectedOption === "default" && (
              <>
                <Form.Field error={!validateTitle}>
                  <label>Notifikations Titel</label>
                  <Input
                    defaultValue={notification.title}
                    onChange={(_, { value }) =>
                      this.updateNotification({ title: value })
                    }
                    disabled={notification.isSent || isNotificationValid}
                  />
                  {!validateTitle && <p style={{ color: "red" }}>{errorMSG}</p>}
                </Form.Field>

                <Form.Field error={!validateMSG}>
                  <label>Notifikations Besked</label>
                  <TextArea
                    defaultValue={notification.messageBody}
                    onChange={(_, { value }) =>
                      this.updateNotification({ messageBody: value })
                    }
                    disabled={notification.isSent || isNotificationValid}
                  />
                </Form.Field>
                {!validateMSG && <p style={{ color: "red" }}>{errorMSG}</p>}

                <Form.Field>
                  <label>Notifikations link</label>
                  <Input
                    defaultValue={notification.link}
                    onChange={(_, { value }) =>
                      this.updateNotification({ link: value })
                    }
                    disabled={isNotificationValid}
                  />
                </Form.Field>
              </>
            )}

            {/* Render Preview based on notification type */}
            {this.state.selectedOption === "domain" && (
              <>
                <Form.Field error={!validateTitle}>
                  <label>Notifikations Titel</label>
                  <Input
                    defaultValue={notification.title}
                    onChange={(_, { value }) =>
                      
                    this.updateNotification({ title: value })
                    }
                    disabled={notification.isSent || isNotificationValid}
                  />
                  {!validateTitle && <p style={{ color: "red" }}>{errorMSG}</p>}
                </Form.Field>

                <Form.Field error={!validateMSG}>
                  <label>Notifikations Besked</label>
                  <TextArea
                    defaultValue={notification.messageBody}
                    onChange={(_, { value }) => {
                      this.updateNotification({ messageBody: value });
                    }}
                    disabled={notification.isSent || isNotificationValid}
                  />
                </Form.Field>
                {!validateMSG && <p style={{ color: "red" }}>{errorMSG}</p>}

                <Form.Field>
                  <label>Notifikations link</label>
                  <Input
                    defaultValue={notification.link}
                    onChange={(_, { value }) =>
                      this.updateNotification({ link: value })
                    }
                    disabled={isNotificationValid}
                  />
                </Form.Field>

                <Form.Field disabled={isNotificationValid}>
                  <label>Notifikations Billede</label>
                  <div>
                    <LogoPicker
                    content="image"
                      accept={["jpg", "png", "svg"]}
                      logo={notification.image}
                      placeholder={"Intet billede valgt"}
                      onChange={(image) => {
                        this.updateNotification({ image });
                      }}
                    />
                    {!validateIMG && <p style={{ color: "red" }}>{errorMSG}</p>}
                  </div>
                </Form.Field>
              </>
            )}
          </Form>

          <Divider hidden />

          <Button
            content="Valider Notifikation"
            onClick={this.handleClick}
            disabled={isNotificationValid}
            color="blue"
            icon="check"
            labelPosition="right"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <>
            <Segment>{this.renderPreview()}</Segment>
          </>
        </Grid.Column>
      </Grid>
    );
  };

  renderPreview = () => {
    const { notification } = this.state;
    const formattedDate = stampDescription(notification.timestamp, "da");

    return (
      <>
        {this.state.selectedOption === "domain" && (
          <>
            <ModalContent style={{ padding: "1rem" }}>
              <Image
                src={notification.image ? `${notification.image}` : logo}
                size="large"
                verticalAlign="center"
                style={{ cursor: "pointer", margin: "auto" }}
                onClick={() =>
                  window.open("https://www.digitalrevisor.nu/", "_blank")
                }
              />
            </ModalContent>

            <ModalHeader
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                color: "black",
                border: "none",
                textAlign: "center",
                lineHeight: "1.1",
              }}
            >
              {notification.title}
            </ModalHeader>
            <ModalContent
              style={{
                padding: "1rem",
                fontSize: "12px",
                color: "black",
                textAlign: "center",
                fontWeight: "light",
              }}
            >
              <p>{notification.messageBody}</p>
            </ModalContent>

            <ModalDescription
              style={{
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button color="green" fluid textAlign="center">
                Læs mere
              </Button>
            </ModalDescription>
          </>
        )}

        {this.state.selectedOption === "default" && (
          <>
            <Feed size="small">
              <FeedEvent>
                <FeedContent>
                  <FeedSummary>
                    <Header as="h3" style={{ color: "black" }}>
                      {notification.title}
                    </Header>
                  </FeedSummary>
                  <FeedExtra text>{notification.messageBody}</FeedExtra>
                  <FeedLike
                    style={{
                      textAlign: "right",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    <p>{formattedDate}</p>
                  </FeedLike>
                </FeedContent>
              </FeedEvent>
              <Divider />
            </Feed>
          </>
        )}
      </>
    );
  };

  renderTargetGroup = () => {
    const { remKey } = this.state;

    return (
      <div>
        <ReportingEntitiesMaster
          initAudience={this.state.notification.audienceSelector}
          forceAdvancedView={true}
          onAudienceSelectorChanged={(audienceSelector) => {
            this.updateNotification({ audienceSelector });
          }}
          audienceSelectorRef={this.audienceSelectorRef}
          notification={this.state.notification}
          key={remKey}
        />
      </div>
    );
  };

  renderSending = () => {
    const {
      notification,
      working,
      isReady,
      isNotificationSent,
      isNotificationValid,
    } = this.state;

    let content;
    if (!notification.isSent) {
      const { ...audienceQuery } = notification.audienceSelector;

      content = (
        <>
          <SendButton
            audienceQuery={audienceQuery}
            onClick={this.doWork(this.sendNotification)}
            disabled={working || !isReady}
          />

          <Divider hidden />

          <Checkbox
            label="Jeg er klar til at sende"
            onChange={(_, { checked }) => this.setState({ isReady: checked })}
            defaultChecked={isReady}
            disabled={!isNotificationValid}
            toggle
          />

          <Divider />

          <Button
            size="small"
            icon="info circle"
            color="blue"
            content="Send en test til en specifik bruger"
            onClick={this.promptUserSpecificTestNotification}
          />
        </>
      );
    }

    return (
      <Segment textAlign="center" basic>
        <Transition.Group>
          {(isNotificationSent || notification.isSent) && (
            <Header as="h2" color="green" icon>
              <Icon name="send" />
              Notifikation afsendt!
              <Header.Subheader>
                Notifikationerne blev sendt afsted d.{" "}
                <AutoStamp stamp={notification.timestamp} />
              </Header.Subheader>
            </Header>
          )}
        </Transition.Group>
        {content}
      </Segment>
    );
  };

  renderSelectedTab = () => {
    const { loading, error } = this.props.rest;
    if (loading || !this.state.notification) {
      return <Loader inline="centered" active />;
    }
    if (error) {
      return <Message error content="Der opstod en fejl" />;
    }
    return this.state.tab.render();
  };

  renderTabs = () => {
    const { isNotificationValid } = this.state;

    return (
      <Menu secondary pointing>
        {this.tabs.map((tab) => {
          return (
            <Menu.Item
              key={tab.name}
              onClick={() => this.setState({ tab, sendError: null })}
              active={tab.name === this.state.tab.name}
              link={tab.name !== this.state.tab.name}
              content={tab.name}
            />
          );
        })}
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              onClick={this.saveAndExit}
              disabled={!isNotificationValid}
              content="Gem"
              labelPosition="right"
              icon="save"
              primary
            />
          </Menu.Item>
          <Menu.Item>
            <Button
              disabled={!isNotificationValid}
              verticalAlign="middle"
              icon="pencil"
              color="blue"
              labelPosition="right"
              content="Rediger"
              onClick={() => this.setState({ isNotificationValid: false })}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  render = () => {
    return (
      <>
        {this.renderTabs()}
        {this.renderSelectedTab()}
      </>
    );
  };
}

export default withUser(
  withRESTResource(NotificationDetail, ({ match }) => {
    return email.getNotification(match.params.notification);
  })
);
