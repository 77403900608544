import React, { useMemo } from 'react';
import { Loader, Segment, Transition } from 'semantic-ui-react';
import reportEditor from 'http/reportEditor';
import createMarkdownFromSection from './createMarkdownFromSection';
import ValidationError from '../Detail/ValidationError';

import styles from './TestHTMLViewer.module.css';

const TestHTMLViewer = ({ sections, error, pdfTemplate, metadata, inputDefinitions, textVariations, selectedBlockID, setSelectedBlockID, isRunning }) => {
    const markdown = useMemo(() => {
        if (!sections) return null;
        if (!metadata) return null;
        return createMarkdownFromSection(sections[0], metadata);
    }, [sections, metadata]);
    
    const renderDocumentElement = ({ sourceDocumentComponent, ...markdown }) => {
        const {
            tag,
            style,
            children,
            innerText,
            ...other
        } = markdown;

        const extraStyle = {};
        const extraProps = {};
        if (sourceDocumentComponent) {
            const blockID = sourceDocumentComponent.corrospondingBuildingBlockID;
            const isSelected = blockID === selectedBlockID;

            extraStyle.cursor = 'pointer';

            if (error && error.data.blockID === blockID) {
                extraProps.className = styles.faultyBlock;
            } else if (isSelected) {
                extraProps.className = styles.selectedBlock;
            }

            extraProps.block = `${sourceDocumentComponent.kind}:${blockID}`;
            extraProps.onClick = e => {
                e.stopPropagation();
                
                if (blockID === selectedBlockID) return;

                const clickedBlock = pdfTemplate.blocks.find(block => block.id === blockID);
                setSelectedBlockID(clickedBlock.id);
            };
        }

        const props = {
            style: { ...style, ...extraStyle },
            ...other,
            ...extraProps,
        };

        let convertedChildren;
        if (innerText) {
            const listOfTexts = Array.isArray(innerText) ? innerText : [innerText];
            
            convertedChildren = listOfTexts.map(ele => {
                if (typeof ele === 'string') {
                    return ele;
                } else {
                    return renderDocumentElement(ele);
                }
            });
        } else if (Array.isArray(children) && children.length > 0) {
            convertedChildren = children.map(renderDocumentElement);
        }

        return React.createElement(tag, props, convertedChildren);
    };

    const documentWrapper = (
        <div style={{ all: 'initial' }}>
            {metadata.fontAssetID && (
                <style>
                    {`
                        @font-face {
                            font-family: CustomFont;
                            src: url('${reportEditor.getAssetURL(metadata.fontAssetID)}') format('truetype');
                            font-weight: normal;
                            font-style: normal;
                        }
                    `}
                </style>
            )}
            <div
                style={{
                    fontFamily: 'CustomFont',
                    borderRadius: '4px',
                    position: 'relative',
                    width: '100vw',
                    height: '100vh',
                }}
            >
                {markdown && renderDocumentElement(markdown)}
            </div>
        </div>
    );

    const validationError = error && (
        <ValidationError
            error={error.data}
            pdfTemplate={pdfTemplate}
            setSelectedBlockID={setSelectedBlockID}
            inputDefinitions={inputDefinitions}
            textVariations={textVariations}
        />
    );

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    zIndex: 1,
                    right: 0,
                }}
            >
                <Transition visible={isRunning} animation='fade down'>
                    <div
                        style={{
                            padding: '1em',
                            backgroundColor: 'white',
                            borderBottomLeftRadius: '1em',
                            borderLeft: '1px solid lightgray',
                            borderBottom: '1px solid lightgray',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Loader inline active size='tiny' />
                        &nbsp;&nbsp;
                        <strong>Generating...</strong>
                    </div>
                </Transition>
            </div>
            {validationError && (
                <Segment
                    style={{
                        position: 'fixed',
                        top: '1em',
                        left: '1em',
                        padding: 0,
                        zIndex: 100,
                    }}
                    raised
                    children={validationError}
                />
            )}
            {documentWrapper}
        </div>
    );
};

export default TestHTMLViewer;