import React from 'react';
import { Form, Segment } from 'semantic-ui-react';

class MultilineQuestion extends React.Component {

    constructor (props) {
        super(props);
        const { isMultiline, allowImportingLastYearValue, maxCharacters } = this.props.node.data;
        this.state = {
            isMultiline,
            allowImportingLastYearValue,
            maxCharacters,
        };
    }

    getData () {
        const { isMultiline, allowImportingLastYearValue, maxCharacters } = this.state;
        
        return {
            isMultiline: isMultiline || false,
            allowImportingLastYearValue: allowImportingLastYearValue || false,
            maxCharacters: maxCharacters || null,
        };
    }

    render () {
        const { isMultiline, allowImportingLastYearValue, maxCharacters } = this.state;
        return (
            <Segment>
                <Form>
                    <Form.Field>
                        <label>Flere linier?</label>
                        <Form.Checkbox
                            toggle
                            checked={isMultiline}
                            onChange={(_, { checked }) => this.setState({ isMultiline: checked })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Tillad import af sidste års værdi?</label>
                        <Form.Checkbox
                            toggle
                            checked={allowImportingLastYearValue}
                            onChange={(_, { checked }) => this.setState({ allowImportingLastYearValue: checked })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Maks antal tegn</label>
                        <Form.Input
                            onChange={(_, { value }) => this.setState({ maxCharacters: Number(value) })}
                            defaultValue={maxCharacters}
                            placeholder='Hvis feltet er tomt er der ingen grænse...'
                        />
                    </Form.Field>
                </Form>
            </Segment>
        );
    }
}

export default MultilineQuestion;