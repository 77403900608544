import { useMemo, useState } from 'react';
import { Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import iconGroups from './icons';

const IconPicker = ({ icon, onChange }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [query, setQuery] = useState('');

    const groupsToShow = useMemo(() => {
        const groupsToShow = [];

        for (const { title, description, icons } of iconGroups) {
            let matchedIcons = [];

            const formattedQuery = query.trim().toLowerCase().replace(/ /g, '');
            if (formattedQuery) {
                matchedIcons = icons.filter(icon => {
                    return icon.toLowerCase().replace(/ /g, '').includes(formattedQuery);
                });
            } else {
                matchedIcons = icons;
            }

            if (matchedIcons.length > 0) {
                groupsToShow.push({
                    title,
                    description,
                    icons: matchedIcons,
                });
            }
        }

        return groupsToShow;
    }, [query]);

    const modalContent = (
        <div>
            <div style={{ marginBottom: '1em' }}>
                <Input
                    autoFocus
                    icon='search'
                    iconPosition='left'
                    placeholder='Search for an icon...'
                    onChange={(_, { value }) => {
                        setQuery(value);
                    }}
                />
            </div>
            {groupsToShow.map(({ title, description, icons }) => {
                return (
                    <div>
                        <Header
                            content={title}
                            subheader={description}
                            dividing
                            style={{ marginBottom: 0 }}
                        />
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {icons.map((icon) => {
                                return (
                                    <div
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            onChange(icon);
                                            setModalOpen(false);
                                        }}
                                    >
                                        <Icon name={icon} size='big' />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <>
            <Modal open={modalOpen} size='large'>
                <Modal.Header>Find icon</Modal.Header>
                <Modal.Content scrolling>{modalContent}</Modal.Content>
                <Modal.Actions>
                    <Button color='black' content='Cancel' onClick={() => setModalOpen(false)} />
                </Modal.Actions>
            </Modal>
            <div>
                <Button
                    basic
                    color='black'
                    size='small'
                    content={icon ? 'Skift ikon' : 'Vælg ikon'}
                    onClick={() => setModalOpen(true)}
                />
                <span style={{ marginLeft: '0.25em' }}>
                    {icon ? <Icon color='black' name={icon} size='large' /> : 'Intet ikon valgt'} 
                </span>
            </div>
        </>
    );
};

export default IconPicker;
