    const setIconName = login => {
        if (login.segment === 'akademi') {
            if(login.isTeacher){
                return 'book';
            }
            return 'student';
        }
        
        if (login.segment === 'privat') {
            return 'user';
        }

        return 'building';
    };

    export default setIconName;