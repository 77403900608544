const scripts = {};

const loadScript = (src, attributes = {}) => {
    scripts[src] = scripts[src] || {
        loaded: false,
        failed: false,
        promise: undefined,
        element: undefined,
    };

    const script = scripts[src];

    if (script.loaded) {
        return Promise.resolve();
    }

    if (script.failed || !script.promise) {
        script.failed = false;
        script.promise = new Promise((resolve, reject) => {
            // get first script element
            const firstScriptElement = document.getElementsByTagName('script')[0];

            // create new script element
            script.element = document.createElement('script');

            // add optional attributes
            for (let [key, value] of Object.entries(attributes)) {
                script.element.setAttribute(key, value);
            }
        
            // set src
            script.element.src = src;
    
            // appending the script to the body causes it to load
            firstScriptElement.parentNode.insertBefore(script.element, firstScriptElement);
    
            // handle success
            script.element.onload = () => {
                script.loaded = true;
                resolve();
            };
    
            // handle error
            script.element.onerror = () => {
                script.failed = true;
                script.element.remove();
                reject(new Error(`failed to load script: ${src}`));
            };
        });
    }

    return script.promise;
};

export default loadScript;