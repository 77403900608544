import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

function Tooltip ({ content, children, icon, color, position, size, trigger, basic }) {
    return <Popup
        basic={basic}
        content={content || children}
        position={position}
        trigger={trigger || <Icon
            color={color}
            name={icon}
            size={size}
        />}
    />;
}

Tooltip.defaultProps = {
    icon: 'question circle outline',
    color: 'blue',
    position: 'top center',
};

export default Tooltip;