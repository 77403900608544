import uuid from 'uuid/v4';
import React, { useState } from 'react';
import { Button, Dropdown, Header, Input, Popup, Table } from 'semantic-ui-react';
import ColoredText from 'atoms/ColoredText';
import FoldableSegment from 'model-editor/molecules/FoldableSegment';
import { confirm } from 'shared/globalModal';

const FunctionCallConfigurationEditor = ({ functionCall, onChange, onDelete, functionDefinition, fieldIds }) => {
    const renderTableHeader = text => {
        return (
            <Table.Row style={{ backgroundColor: 'lightgray' }}>
                <Table.Cell colSpan={3}>
                    <Header>{text}</Header>
                </Table.Cell>
            </Table.Row>
        );
    };

    const updateInputMapping = (id, tag) => {
        const copy = { ...functionCall };
        copy.inputMappings[id] = tag;
        onChange(copy);
    };

    const updateOutputMapping = (id, tag) => {
        const copy = { ...functionCall };
        copy.outputMappings[id] = tag;
        onChange(copy);
    };

    const onDeleteClicked = async () => {
        if (await confirm('Er du sikker?')) {
            onDelete();
        }
    };

    return (
        <div>
            <Table compact='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Datatype</Table.HeaderCell>
                        <Table.HeaderCell>Tag</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderTableHeader('Input')}
                    {functionDefinition.inputDefinitions.map(({ id, dataType }) => {
                        const mappings = functionCall.inputMappings;

                        const options = (
                            fieldIds
                            .filter(field => field.dataType === dataType)
                            .filter(field => !Object.values(functionCall.outputMappings).includes(field.id))
                            .map(({ name, id }) => {
                                return {
                                    text: name,
                                    value: id,
                                    key: id,
                                };
                            })
                        );

                        return (
                            <Table.Row key={id}>
                                <Table.Cell>{id}</Table.Cell>
                                <Table.Cell>{dataType}</Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        selection
                                        search
                                        fluid
                                        placeholder='Vælg et tag som inputværdi'
                                        defaultValue={mappings[id]}
                                        options={options}
                                        onChange={(_, { value }) => updateInputMapping(id, value)}
                                        clearable
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}

                    {renderTableHeader('Output')}
                    {functionDefinition.outputDefinitions.map(({ id, dataType }) => {
                        const mappings = functionCall.outputMappings;
                        const tag = mappings[id];
                        const badTagInput = tag && !/^[0-9a-z_]+$/g.test(tag);

                        return (
                            <Table.Row key={id}>
                                <Table.Cell width={4}>{id}</Table.Cell>
                                <Table.Cell width={4}>{dataType}</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        fluid
                                        placeholder='Indtast et tag for denne outputværdi'
                                        defaultValue={tag}
                                        onChange={(_, { value }) => updateOutputMapping(id, value)}
                                        error={badTagInput}
                                    />
                                    {badTagInput && (
                                        <ColoredText
                                            color='red'
                                            content='Tag må kun bestå af små bogstaver, tal og bundstreg'
                                        />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={3}>
                            <Button color='red' icon='trash' basic onClick={onDeleteClicked} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

const FunctionCallsEditor = ({ functionCalls, onChange, fieldIds, resourceFunctions }) => {
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <div>
            {functionCalls.map((config, i) => {
                return (
                    <FoldableSegment header={config.functionID} icon='cog'>
                        <FunctionCallConfigurationEditor
                            fieldIds={fieldIds}
                            functionCall={config}
                            functionDefinition={resourceFunctions.find(resourceFunc => resourceFunc.functionID === config.functionID)}
                            onDelete={() => {
                                const copy = [...functionCalls];
                                copy.splice(i, 1);
                                onChange(copy);
                            }}
                            onChange={newConfig => {
                                const copy = [...functionCalls];
                                copy[i] = newConfig;
                                onChange(copy);
                            }}
                        />
                    </FoldableSegment>
                );
            })}
            <div style={{ textAlign: 'right' }}>
                <Popup
                    basic
                    on='click'
                    position='top center'
                    open={popupOpen}
                    onOpen={() => setPopupOpen(true)}
                    onClose={() => setPopupOpen(false)}
                    trigger={
                        <Button
                            content='Opret funktion'
                            icon='plus'
                            primary
                        />
                    }
                    content={
                        <>
                            <div>
                                <strong>Vælg funktion</strong>
                            </div>
                            {resourceFunctions.map(({ functionID }) => {
                                return (
                                    <div>
                                        <ColoredText
                                            link
                                            underlined={false}
                                            content={functionID}
                                            onClick={() => {
                                                setPopupOpen(false);
                                                onChange([...functionCalls, {
                                                    tag: uuid(),
                                                    functionID,
                                                    inputMappings: {},
                                                    outputMappings: {},
                                                }]);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default FunctionCallsEditor;