import { useEffect, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useReportTemplatesConfig } from './config';
import * as blockService from './blockService';

const PreviewWindowManager = ({ metadata, pdfTemplate, inputDefinitions, userContext, textVariations, setPdfTemplate }) => {
    const [previewWindow, setPreviewWindow] = useState(null);
    const [chosenLanguage] = useReportTemplatesConfig('chosenLanguage', null);
    const [selectedBlockID, setSelectedBlockID] = useReportTemplatesConfig('selectedField', null);

    const stateForWindow = useMemo(() => {
        return {
            metadata,
            pdfTemplate,
            inputDefinitions,
            userContext,
            textVariations,
            chosenLanguage,
            selectedBlockID,
        };
    }, [metadata, pdfTemplate, inputDefinitions, userContext, textVariations, chosenLanguage, selectedBlockID]);

    const togglePreviewWindow = () => {
        const templateViewerPath = '/model-editor/report-template-viewer';
        const newWindow = window.open(templateViewerPath, '_blank', 'width=800,height=600');
        setPreviewWindow(newWindow);
    };

    useEffect(() => {
        if (!previewWindow) {
            return;
        }

        const closedChecker = window.setInterval(() => {
            if (previewWindow.closed) {
                setPreviewWindow(null);
            }
        }, 1000);

        const handleMessage = e => {
            if (e.origin !== window.origin) return;

            const { eventKind, data } = e.data;

            switch (eventKind) {
                case 'ready': {
                    previewWindow.postMessage({
                        eventKind: 'stateChange',
                        data: stateForWindow,
                    }, '*');
                    break;
                }

                case 'blockSelected': {
                    const blockID = data;
                    const blocks = [...pdfTemplate?.blocks];
                    blockService.expandTargetBlock(blocks, blockID);
                    setPdfTemplate({
                        ...pdfTemplate,
                        blocks,
                    });
                    setSelectedBlockID(data);
                    break;
                }

                default: {
                    console.error('Got unknown event kind:', eventKind);
                    break;
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.clearInterval(closedChecker);
            window.removeEventListener('message', handleMessage);
        };
    }, [previewWindow, stateForWindow, pdfTemplate]);

    useEffect(() => {
        return () => previewWindow?.close();
    }, [previewWindow]);

    useEffect(() => {
        if (!previewWindow) return;

        const payload = {
            eventKind: 'stateChange',
            data: stateForWindow,
        };

        previewWindow.postMessage(payload, '*');
    }, [previewWindow, stateForWindow]);

    return (
        <Button
            content='Open preview window'
            disabled={previewWindow !== null}
            onClick={togglePreviewWindow}
        />
    );
};

export default PreviewWindowManager;