import React, { useState } from 'react';
import lodash from 'lodash';
import { Button, Checkbox, Dropdown, Form, Icon, Input, Modal, Table } from 'semantic-ui-react';
import { nodeMetadata, nodeTypes, dataTypes } from 'model-editor/nodeMetadata';
import RadioQuestionEditor from 'model-editor/atoms/RadioQuestionEditor';
import { prompt } from 'shared/globalModal';
import { createSlug } from 'shared/createSlug';

// prepare map[dataType]questionType
const dataTypeToQuestionType = {};
for (let [nodeType, nodeData] of Object.entries(nodeMetadata)) {
    if (nodeData.type !== nodeTypes.Question) {
        continue;
    }

    dataTypeToQuestionType[nodeData.dataType] = nodeType;
}

const questionTypesWithExtraConfiguration = {
    [dataTypes.NUMBER]: (question, onChange) => {
        return (
            <Checkbox
                label='Spørg efter sidste års værdi?'
                checked={question.askForLastYear}
                onChange={(_, { checked }) => onChange({ ...question, askForLastYear: checked })}
            />
        );
    },

    [dataTypes.STRING]: (question, onChange) => {
        return (
            <Checkbox
                label='Stor tekstboks?'
                checked={question.isMultiline}
                onChange={(_, { checked }) => onChange({ ...question, isMultiline: checked })}
            />
        );
    },

    [dataTypes.ENUM_STRING]: (question, onChange) => {
        const formattedOptions = (question.options || []).map(({ optionText, optionValue, optionTooltip }) => {
            return {
                label: optionText,
                value: optionValue,
                tooltip: optionTooltip,
            };
        });

        return (
            <RadioQuestionEditor
                answers={formattedOptions}
                onChange={options => onChange({
                    ...question,
                    options: options.map(({ label, value, tooltip }) => {
                        return {
                            optionText: label,
                            optionValue: value,
                            optionTooltip: tooltip,
                        };
                    })
                })}
            />
        );
    },

    [dataTypes.BOOLEAN]: (question, onChange) => {
        return (
            <Form>
                <Form.Field>
                    <label>Ja-label</label>
                    <Input
                        fluid
                        defaultValue={question.alternateYes}
                        onChange={(_, { value }) => onChange({ ...question, alternateYes: value })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Nej-label</label>
                    <Input
                        fluid
                        defaultValue={question.alternateNo}
                        onChange={(_, { value }) => onChange({ ...question, alternateNo: value })}
                    />
                </Form.Field>
            </Form>
        );
    },
};

const QuestionEditor = ({ onChange, questions }) => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(-1);

    const updateQuestion = (index, propertyPath, value) => {
        lodash.set(questions[index], propertyPath, value);
        onChange(questions);
    };

    const removeQuestion = index => {
        questions.splice(index, 1);
        onChange(questions);
    };

    const createNewQuestion = async () => {
        const inputTag = await prompt('Navngiv spørgsmålet');
        if (!inputTag) {
            return;
        }

        const tag = createSlug(inputTag);
        if (!tag) {
            return;
        }

        const newQuestion = {
            tag,
            type: dataTypes.NUMBER,
        };

        onChange([...questions, newQuestion]);
    };

    const renderQuestionOptionsModal = () => {
        if (activeQuestionIndex === -1) {
            return null;
        }

        const activeQuestion = questions[activeQuestionIndex];
        if (!activeQuestion) {
            return null;
        }
        
        const handleQuestionChanged = newQuestionData => {
            const copy = [...questions];
            copy[activeQuestionIndex] = newQuestionData;
            onChange(copy);
        };

        const renderForm = questionTypesWithExtraConfiguration[activeQuestion.type];

        return (
            <Modal open onClose={() => setActiveQuestionIndex(-1)} closeIcon>
                <Modal.Header>Indstillinger for {activeQuestion.tag}</Modal.Header>
                <Modal.Content>
                    {renderForm(activeQuestion, handleQuestionChanged)}
                </Modal.Content>
            </Modal>
        );
    };

    const questionsTable = (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tag</Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {questions.map(({ tag, type }, i) => {
                    const hasExtraConfiguration = type in questionTypesWithExtraConfiguration;

                    return (
                        <Table.Row key={tag}>
                            <Table.Cell width={7}>
                                {tag}
                            </Table.Cell>
                            <Table.Cell width={7}>
                                <Dropdown
                                    defaultValue={type}
                                    fluid
                                    selection
                                    options={Object.keys(dataTypeToQuestionType).map(dataType => ({
                                        text: dataTypeToQuestionType[dataType],
                                        value: dataType,
                                    }))}
                                    onChange={(_, { value }) => {
                                        updateQuestion(i, 'type', value);
                                    }}
                                />
                            </Table.Cell>
                            <Table.Cell textAlign='right' width={2}>
                                <Icon
                                    name='cog'
                                    size='large'
                                    title='Slet'
                                    onClick={() => hasExtraConfiguration && setActiveQuestionIndex(i)}
                                    link={hasExtraConfiguration}
                                    disabled={!hasExtraConfiguration}
                                />
                                <Icon
                                    link
                                    name='x'
                                    color='red'
                                    size='large'
                                    title='Slet'
                                    onClick={() => removeQuestion(i)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={3} textAlign='right'>
                        <Button
                            onClick={createNewQuestion}
                            content='Opret spørgsmål'
                            icon='plus'
                            primary
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );

    return (
        <>
            {renderQuestionOptionsModal()}
            {questionsTable}
        </>
    );
};

export default QuestionEditor;