import { useEffect, useState } from 'react';

const useSearch = (searchHandler) => {
    const [query, setQuery] = useState('');
    const [error, setError] = useState(false);
    const [searching, setSearching] = useState(false);
    const [nextSearch, setNextSearch] = useState(null);
    const [lastSearch, setLastSearch] = useState(null);
    const [results, setResults] = useState(null);

    useEffect(() => {
        setError(false);
        setNextSearch(query);
    }, [query]);

    useEffect(() => {
        if (searching) {
            return;
        }

        if (lastSearch === nextSearch) {
            return;
        }

        // do search
        const doSearch = async () => {
            const query = nextSearch?.trim();
            setSearching(true);
            setLastSearch(query);
            setNextSearch(null);
            try {
                let results;

                if (query.length > 2) {
                    results = await searchHandler(query);
                }

                setResults(results);
            } catch {
                setError(true);
            };
            setSearching(false);
        };

        doSearch();
    }, [nextSearch, searching, lastSearch, searchHandler]);

    return {
        query,
        setQuery,
        error,
        results,
        searching,
    };
};

export default useSearch;