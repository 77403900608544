import { useCallback, useEffect, useState } from 'react';
import { Divider, Dropdown, Icon, Image, Input, Modal, Pagination, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { readAsBase64 } from 'shared/files';
import reportEditor from 'http/reportEditor';
import AutoStamp from 'atoms/AutoStamp';
import FileuploadButton from 'atoms/FileuploadButton';

const ImageAssetsModal = ({ images, onUpload, loading, error, onImageSelected, selectedImage }) => {
    const [open, setOpen] = useState(false);

    const iconProps = {};
    if (loading) {
        iconProps.name = 'spinner';
        iconProps.loading = true;
    } else if (error) {
        iconProps.name = 'warning sign';
        iconProps.color = 'red';
    } else {
        iconProps.name = 'upload';
        iconProps.link = true;
        iconProps.onClick = () => setOpen(true);
    }

    return (
        <>
            <Icon {...iconProps} />
            {open && (
                <Modal open onClose={() => setOpen(false)}>
                    <Modal.Content>
                        <ImageAssetsManager
                            selectedImage={selectedImage}
                            onImageSelected={onImageSelected}
                            images={images}
                            onUpload={onUpload}
                        />
                    </Modal.Content>
                </Modal>
            )}
        </>
    );
};

const ImageAssetsManager = ({ images, onUpload, onImageSelected, selectedImage }) => {
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (query.length > 0) {
            setPage(0);
        }
    }, [query]);

    const uploadImage = async file => {
        const dataBase64 = await readAsBase64(file);
        const contentType = file.type;
        const fileName = file.name;

        try {
            const { assetID } = await reportEditor.uploadAsset({ category: 'image', dataBase64, contentType, fileName });
            onUpload();
            onImageSelected(assetID);
        } catch (e) {
            toast.error(e.message);
        }
    };

    const pageSize = 5;

    const filteredImages = images.filter(asset => {
        if (!query) return true;
        return asset.fileName.toLowerCase().includes(query.toLowerCase());
    });

    const imagesToShow = filteredImages.slice(page * pageSize, (page * pageSize) + pageSize);
    const amountOfImagesTotal = filteredImages.length;
    const amountOfPages = Math.ceil(amountOfImagesTotal / pageSize);

    return (
        <div>
            <div>
                <h2 style={{ display: 'inline' }}>Images</h2>
                <Input
                    icon='search'
                    placeholder='Search...'
                    style={{ float: 'right' }}
                    onChange={(_, { value }) => setQuery(value)}
                />
            </div>
            <Divider />
            {imagesToShow.map(asset => {
                const isSelected = asset._id === selectedImage;
                return (
                    <Segment
                        key={asset._id}
                        secondary={!isSelected}
                        color={isSelected ? 'green' : undefined}
                        onClick={() => onImageSelected(asset._id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1em' }}>
                            <div>
                                <Image
                                    circular
                                    size='tiny'
                                    src={reportEditor.getAssetURL(asset._id)}
                                    style={{ border: '1px solid lightgray' }}
                                />
                            </div>
                            <div>
                                <strong>{asset.fileName}</strong> <br />
                                Created: <AutoStamp stamp={asset.uploadTimestamp} />
                            </div>
                        </div>
                    </Segment>
                );
            })}
            {amountOfImagesTotal > 0 && <Divider />}
            <Pagination
                activePage={page + 1}
                totalPages={amountOfPages}
                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
            />
            <FileuploadButton
                floated='right'
                onChange={uploadImage}
            />
        </div>
    );
};

let cachedAssetsPromise;

const ImageAssetFormField = ({ label, defaultValue, onChange }) => {
    const [images, setImages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const doFetchAssets = useCallback(() => {
        if (!cachedAssetsPromise) {
            cachedAssetsPromise = reportEditor.getAssets('image');
        }

        cachedAssetsPromise
            .then(images => setImages(images))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        doFetchAssets();
    }, [doFetchAssets]);

    const onImageUploaded = () => {
        cachedAssetsPromise = null;
        doFetchAssets();
    };

    return (
        <>
            <label>
                {label}&nbsp;
                <ImageAssetsModal
                    images={images}
                    loading={loading}
                    error={error}
                    selectedImage={defaultValue}
                    onImageSelected={imageID => onChange(imageID)}
                    onUpload={onImageUploaded}
                />
            </label>
            <Dropdown
                selection
                search
                clearable
                loading={loading}
                error={!!error}
                value={defaultValue}
                onChange={(_, { value }) => onChange(value)}
                options={images?.map(asset => {
                    return {
                        text: asset.fileName,
                        value: asset._id,
                    };
                })}
            />
        </>
    );
};

export default ImageAssetFormField;