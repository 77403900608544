import client from './client';
const PREFIX = '/product-engine/api';

function countFinishedXBRLReports() {
    return client.getResource(`${PREFIX}/xbrlreports/finished`);
}

function countTotalTaxReturns() {
    return client.getResource(`${PREFIX}/tax-returns/total`);
}

function testCalculation(tokens, references) {
    return client.postResource(`${PREFIX}/calculation/test`, {
        tokens,
        references,
    });
}

function testCalculations({ calculations, references }) {
    return client.postResource(`${PREFIX}/calculations/test`, {
        calculations,
        references,
    });
}

function getUserProductStatuses(uid) {
    return client.getResource(`${PREFIX}/statuses/${uid}`);
}

function getUserActiveProducts(uid) {
    return client.getResource(`${PREFIX}/active-products/${uid}`);
}

function getUserFacts(uid, productID, label) {
    return client.getResource(`${PREFIX}/admin/user/${uid}/product/${productID}/facts?label=${label}`);
}

function getProduct(productID) {
    return client.getResource(`${PREFIX}/${productID}`);
}

function getResourceTemplates() {
    return client.getResource(`${PREFIX}/resource-templates`);
}

function getResourceTemplate(slug) {
    return client.getResource(`${PREFIX}/resource-templates/${slug}`);
}

function createResourceTemplate(resourceTemplate) {
    return client.postResource(`${PREFIX}/resource-templates`, resourceTemplate);
}

function updateResourceTemplate(slug, resourceTemplate) {
    return client.putResource(`${PREFIX}/resource-templates/${slug}`, resourceTemplate);
}

function getResourceFunctions() {
    return client.getResource(`${PREFIX}/resource-functions`);
}

function getDataSourceTables() {
    return client.getResource(`${PREFIX}/data-source-tables`);
}

function getDataSourceTableBySlug(slug) {
    return client.getResource(`${PREFIX}/data-source-tables/${slug}`);
}

function upsertDataSourceTable(dataSouceTable) {
    return client.putResource(`${PREFIX}/data-source-tables`, dataSouceTable);
}

const productEngine = {
    countFinishedXBRLReports,
    countTotalTaxReturns,
    testCalculation,
    testCalculations,
    getUserProductStatuses,
    getUserActiveProducts,
    getUserFacts,
    getProduct,
    getResourceTemplates,
    getResourceTemplate,
    createResourceTemplate,
    updateResourceTemplate,
    getResourceFunctions,
    getDataSourceTables,
    getDataSourceTableBySlug,
    upsertDataSourceTable,
};

export default productEngine;