import { dataTypes, nodeTypes } from 'model-editor/nodeMetadata';

const prepareFieldTag = ({ id, name, dataType, nodeType }) => {
    return {
        id,
        name,
        dataType,
        type: nodeType,
    };
};

const collectAllTagsAssociatedWithResource = ({
    chosenResource,
    resourceData,
    lastYearResourceData,
    resourceFunctions = [],
    dependencyNodes = [],
}) => {
    const calculations = resourceData.calculations || [];
    const questions = resourceData.questions || [];
    const functionCalls = resourceData.functionCalls || [];

    // prepare properties from selected resource
    const resourceTags = [];

    if (chosenResource) {
        resourceTags.push(...(chosenResource.properties || []).map(prop => {
            return prepareFieldTag({
                id: prop.tag,
                name: prop.name,
                dataType: prop.dataType,
                nodeType: nodeTypes.StaticValue,
            });
        }));

        resourceTags.push(...(chosenResource.annualRolloverValues || []).map(({ tag }) => {
            return prepareFieldTag({
                id: tag,
                name: tag,
                dataType: dataTypes.NUMBER,
                nodeType: nodeTypes.StaticValue,
            });
        }));
    }

    calculations.forEach(({ tag }) => {
        resourceTags.push(prepareFieldTag({
            name: tag,
            id: tag,
            dataType: dataTypes.NUMBER,
            nodeType: nodeTypes.Calculation,
        }));
    });
    
    questions.forEach(({ tag, type }) => {
        resourceTags.push(prepareFieldTag({
            name: tag,
            id: tag,
            dataType: type,
            nodeType: nodeTypes.Question,
        }));
    });

    functionCalls.forEach(call => {
        const resourceFunction = resourceFunctions.find(({ functionID }) => functionID === call.functionID);
        if (!resourceFunction) {
            return;
        }

        for (const [id, tag] of Object.entries(call.outputMappings)) {
            if (!tag) {
                continue;
            }
            
            const functionOutput = resourceFunction.outputDefinitions.find(output => output.id === id);
            if (!functionOutput) {
                continue;
            }

            resourceTags.push(prepareFieldTag({
                name: tag,
                id: tag,
                dataType: functionOutput.dataType,
                nodeType: nodeTypes.Calculation, 
            }));
        }
    });

    dependencyNodes.forEach(node => {
        if (!node.tag) {
            return;
        }

        resourceTags.push(prepareFieldTag({
            name: node.name,
            id: node.tag,
            dataType: node.dataType,
            nodeType: nodeTypes.StaticValue,
        }));
    });

    if (lastYearResourceData) {
        const primoValueTags = collectAllTagsAssociatedWithResource({
            chosenResource,
            resourceFunctions,
            resourceData: lastYearResourceData,
        });

        const formattedNumberPrimoValues = (
            primoValueTags
            .filter(tag => tag.dataType === 'Number')
            .filter(tag => tag.type === 'Calculation')
            .map(({ id, name, ...tag }) => {
                return {
                    id: `PRIMO_${id}`,
                    name: `[Primo] ${name}`,
                    ...tag
                };
            })
        );

        resourceTags.push(...formattedNumberPrimoValues);
    }

    return resourceTags;
};

export default collectAllTagsAssociatedWithResource;