import React from 'react';
import { Dropdown, Form, Input, Segment } from 'semantic-ui-react';
import NodeDataView from './NodeDataView';
import reportEditor from 'http/reportEditor';
import { truncString } from 'shared/truncString';
import useRestResource from 'shared/hooks/useRestResource';

const VersionPicker = ({ reportTemplateID, value, onChange }) => {
    const { loading, data, error } = useRestResource({
        fetcher: () => {
            if (!reportTemplateID) {
                return Promise.resolve([]);
            }
            return reportEditor.getExportedTemplateVersions(reportTemplateID);
        },
        args: [reportTemplateID],
    });

    const versions = data || [];

    return (
        <Dropdown
            fluid
            selection
            clearable
            loading={loading}
            error={error}
            disabled={versions.length === 0}
            onChange={(_, { value }) => {
                const selectedTemplate = versions.find(v => v.version === value);
                onChange(value, selectedTemplate.outputAsIXBRL);
            }}
            value={value}
            options={versions.map(({ version, description }) => ({
                value: version,
                text: `Version ${version} - ${truncString(description)}`,
            }))}
        />
    );
};

class NodeDataYearReportData extends React.Component {
    state = {
        loading: true,
        selectedReportTemplate: '',
        selectedTemplateVersion: -1,
        renderSelectedTemplateAsIXBRL: false,
        taxBookletDocument: null,
        templates: null,
    };

    componentDidMount = async () => {
        const { selectedReportTemplate, selectedTemplateVersion, renderSelectedTemplateAsIXBRL, secondaryDocuments } = this.props.node.data;

        const templates = await reportEditor.getTemplates();
        const [taxBookletDocument] = secondaryDocuments || [];

        this.setState({
            taxBookletDocument,
            selectedReportTemplate,
            selectedTemplateVersion,
            renderSelectedTemplateAsIXBRL,
            templates,
            loading: false,
        });
    };

    getView () {
        let view = null;
        if (this.data && this.data.getView) {
            view = this.data.getView();
        }
        return view;
    }

    async getData() {
        const { selectedReportTemplate, selectedTemplateVersion, renderSelectedTemplateAsIXBRL, taxBookletDocument } = this.state;

        const taxBookletValid = (
            taxBookletDocument &&
            taxBookletDocument.reportTemplateID &&
            taxBookletDocument.templateVersion >= 1 &&
            taxBookletDocument.title
        );

        return {
            selectedReportTemplate,
            selectedTemplateVersion,
            renderSelectedTemplateAsIXBRL,
            secondaryDocuments: taxBookletValid ? [taxBookletDocument] : [],
        };
    }

    updateTaxBooklet = (updator) => {
        const { taxBookletDocument } = this.state;

        this.setState({
            taxBookletDocument: { ...taxBookletDocument, ...updator },
        });
    };

    renderDropdownLoadingPlaceholder = () => {
        return (
            <Dropdown
                key={Math.random()}
                fluid
                selection
                loading
                disabled
            />
        );
    };

    renderTemplatePicker = ({ selectedReportTemplate, onChange }) => {
        const { templates, loading } = this.state;

        return (
            <Dropdown
                fluid
                selection
                search
                clearable
                loading={loading}
                onChange={(_, { value }) => onChange(value)}
                value={selectedReportTemplate}
                options={templates?.map(({ name, id }) => ({
                    value: id,
                    text: name,
                }))}
            />
        );
    };

    render () {
        const { modelId, node } = this.props;
        const { selectedReportTemplate, selectedTemplateVersion, taxBookletDocument } = this.state;
        
        return (
            <div>
                <Segment>
                    <h3>Main report</h3>
                    <Form>
                        <Form.Field>
                            <label>Select a template</label>
                            {this.renderTemplatePicker({
                                selectedReportTemplate,
                                onChange: value => this.setState({ selectedReportTemplate: value, selectedTemplateVersion: -1 }),
                            })}
                        </Form.Field>
                        <Form.Field>
                            <label>Select a version</label>
                            <VersionPicker
                                reportTemplateID={selectedReportTemplate}
                                value={selectedTemplateVersion}
                                onChange={(selectedTemplateVersion, renderSelectedTemplateAsIXBRL) => {
                                    this.setState({
                                        selectedTemplateVersion,
                                        renderSelectedTemplateAsIXBRL,
                                    });
                                }}
                            />
                        </Form.Field>
                    </Form>
                </Segment>

                <Segment>
                    <h3>Tax booklet</h3>
                    <Form>
                        <Form.Field>
                            <label>Select a template</label>
                            {this.renderTemplatePicker({
                                selectedReportTemplate: taxBookletDocument?.reportTemplateID,
                                onChange: value => this.updateTaxBooklet({ reportTemplateID: value, templateVersion: -1 }),
                            })}
                        </Form.Field>
                        <Form.Field>
                            <label>Select a version</label>
                            <VersionPicker
                                reportTemplateID={taxBookletDocument?.reportTemplateID}
                                value={taxBookletDocument?.templateVersion}
                                onChange={templateVersion => this.updateTaxBooklet({ templateVersion })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Input a title</label>
                            <Input
                                fluid
                                defaultValue={taxBookletDocument?.title}
                                onChange={(_, { value }) => this.updateTaxBooklet({ title: value })}
                            />
                        </Form.Field>
                    </Form>
                </Segment>
                
                <NodeDataView
                    ref={data => this.data = data}
                    node={node}
                    modelId={modelId}
                />
            </div>
        );
    }

}

export default NodeDataYearReportData;