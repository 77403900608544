import interpret from '@digital-revisor/node-formula-interpreter';
import findAllReferences from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';
import { ruleToCalculation } from 'model-editor/util/rule';
import collectAllTagsAssociatedWithResource from './collectAllTagsAssociatedWithResource';

const convertRuleToCalculation = (field, rule, tagMap, isVisibilityRule, isDeactivationRule) => {
    const ruleFieldTag = tagMap[rule.refNode];
    const ruleDataType = ruleFieldTag?.dataType;
    const convertedRule = ruleToCalculation(rule, ruleDataType);
    
    // inject node ID && field ID && type
    convertedRule.nodeID = field.nodeId;
    convertedRule.fieldID = field.id;
    convertedRule.isVisibilityRule = isVisibilityRule;
    convertedRule.isDeactivationRule = isDeactivationRule;

    return convertedRule;
};

const visibilityRuleToCalculation = (field, rule, tagMap) => {
    return convertRuleToCalculation(field, rule, tagMap, true, false);
};

const validationRuleToCalculation = (field, rule, tagMap) => {
    return convertRuleToCalculation(field, rule, tagMap, false, false);
};

const deactivationRuleToCalculation = (rule, tagMap) => {
    return convertRuleToCalculation({}, rule, tagMap, false, true);
};

const prepareResourceForSave = ({
    chosenResource,
    resourceData,
    resourceFunctions = [],
    dependencyNodes = [],
}) => {
    const allResourceTags = collectAllTagsAssociatedWithResource({
        chosenResource,
        resourceData,
        resourceFunctions,
        dependencyNodes,
    });

    const tagMap = {};
    for (const resourceTag of allResourceTags) {
        tagMap[resourceTag.id] = resourceTag;
    }

    // prepare calculations
    const parsedCalculations = (resourceData.calculations || []).map(calculation => {
        const parsedExpression = interpret(calculation.expression);
        const refs = findAllReferences(parsedExpression);

        return {
            ...calculation,
            parsedExpression: JSON.stringify(parsedExpression),
            requiredTags: refs,
        };
    });

    // parse rules from field templates
    const parsedRules = [];
    for (let fieldTemplate of (resourceData.fieldTemplates || [])) {
        for (let field of fieldTemplate.fields) {
            const {
                visibilityRules,
                validationRules,
            } = field;

            visibilityRules && parsedRules.push(...visibilityRules.map(rule => {
                return visibilityRuleToCalculation(field, rule, tagMap);
            }));

            validationRules && parsedRules.push(...validationRules.map(rule => {
                return validationRuleToCalculation(field, rule, tagMap);
            }));
        }
    }

    // parse deactivation rules
    (resourceData.deactivationRules || []).forEach(rule => {
        parsedRules.push(deactivationRuleToCalculation(rule, tagMap));
    });

    return {
        ...resourceData,
        calculations: parsedCalculations,
        rules: parsedRules,
    };
};

export default prepareResourceForSave;